.cookies-banner {
    background: #222020;
    color: #fff;
    font-weight: 100;
    font-family: 'Crete Round', sans-serif;
    border-bottom: 1px solid #ccc;
    position: fixed;
    bottom: 0px;
    padding: 5px 0;
    z-index: 99;
    text-align: center;
    float: left;
    width: 100%;
    transition: none;
    -webkit-transition: none;
    box-sizing: border-box;
    margin: 0;
}

.cookies-banner-container {
    float: left;
    width: 100%;
    position: relative;
}

.cookies-banner-info {
    line-height: 1.7em;
    font-size: 11px;
    display: inline-block;
    /* padding: 5px 0px 0px; */
    /* margin: 0px auto; */
    /* display: inline-block; */
}

.cookies-banner-close {
    padding: 2px 20px;
    display: inline-block;
}